import { render, staticRenderFns } from "./PageBreadcrumb.vue?vue&type=template&id=474b1ba6&"
import script from "./PageBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./PageBreadcrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLink: require('/builds/docue/website/components/CustomLink.vue').default,IconSlash: require('/builds/docue/website/components/Icon/IconSlash.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { meta } from '~/utils/meta'
export default {
  data () {
    return {
      articles: []
    }
  },
  fetchKey (getCounter) {
    return `blog-index:${this.$route.path}:${getCounter('blog-index-page')}`
  },
  async fetch () {
    const response = await this.$sb.list(this.$url.blogListing().substring(1), {
      sort_by: 'first_published_at:desc',
      per_page: 100,
      'filter_query[component][in]': 'blogpost',
    })
    this.articles = response.data.stories.map((story) => {
      return {
        id: story.uuid,
        title: story.content.title,
        author: story.content.author,
        intro: story.content.intro,
        slug: story.slug,
        date: new Date(story.first_published_at || story.created_at),
        image: this.$sb.parseImage(story.content.image),
        author_image: this.$sb.parseImage(story.content.author_image)
      }
    })
  },
  head () {
    return meta({
      title: this.$t('blogListingPage.titleV2'),
      description: this.$t('blogListingPage.description'),
    })
  }
}

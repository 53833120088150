//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LAYOUT_HEADER_STYLE_WHITE } from '~/utils/constants'
export default {
  props: {
    headerStyle: {
      type: String,
      default: LAYOUT_HEADER_STYLE_WHITE
    },
    signupText: {
      type: String,
      default: null
    },
    signupUrl: {
      type: String,
      default: null
    },
    signupAction: {
      type: Function,
      default: null
    },
    signupVariant: {
      type: String,
      default: 'red',
    },
    shareTitle: {
      type: String,
      default: null
    }
  }
}
